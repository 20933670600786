import React, {useEffect, useState} from 'react';
import {
    Switch,
    Route,
    useLocation
} from 'react-router-dom';
import './css/style.scss';
import AOS from 'aos';
import {focusHandling} from 'cruip-js-toolkit';
import Play from './pages/Play';
import Leaderboards from './pages/Leaderboards';
import MyArcades from './pages/MyArcades';
import _404 from './pages/404';
import WalletConnectProvider from "@walletconnect/web3-provider";
import {
    INFURA_ID,
    TOADRUNNERZ_CONTRACT_ADDRESS, GENESIS_CONTRACT_ADDRESS, TOADRUNNERZ_ABI, GENESIS_ABI
} from './constants/vars';
import {
    FIR_CONFIG
} from './constants/config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WalletLink from 'walletlink'

firebase.initializeApp(FIR_CONFIG);
const axios = require('axios').default;
const Web3 = require("web3");
const walletConnectProvider = new WalletConnectProvider({
    infuraId: INFURA_ID, // Required
});
const walletLink = new WalletLink({
    appName: "Arcade NFT",
    appLogoUrl: require("../src/assets/images/arcade-logo.png"),
    darkMode: localStorage.getItem('dark-mode') === null || localStorage.getItem('dark-mode') === 'true',
})

const MAINTENANCE = false;

function App() {

    const metamaskLogin = () => {
        if (isMetaMaskEnabled()) {
            initUserWallet("metamask");
        } else {
            window.open("https://metamask.io/download", "_blank")
        }
    }

    const walletLinkLogin = () => {
        window.ethereum = walletLink.makeWeb3Provider("https://mainnet.infura.io/v3/78b30def5a924d86829f2d2678619aa9", 1)
        initUserWallet("walletlink")
    }

    const walletConnectLogin = async () => {
        try {
            await walletConnectProvider.enable();
        } catch (e) {
            window.location.reload();
            //console.log(e);
        }
    }

    const location = useLocation();

    const [user, setUser] = useState(() => {
        return {
            connected: false,
            walletAddress: "",
            chainId: null,
            balance: 0,
            walletType: null,
            arcadeClassicsTokens: [],
            toadRunnerzTokens: [],
            desertTokens: [],
            aquaticTokens: [],
            spaceTokens: [],
            racingTokens: [],
            genesisTokens: [],
            toadRunnerzContract: null,
            genesisContract: null,
            fireRef: null,
            username: "",
            formattedWalletAddress: "",
            signInWithMetamask: metamaskLogin,
            signInWithWalletConnect: walletConnectLogin,
            signInWithWalletLink: walletLinkLogin,
        }
    });

    const initUserWallet = async (walletType) => {
        window.web3 = walletType === "walletconnect" ? new Web3(walletConnectProvider) : new Web3(window.ethereum);
        try {
            const accounts = walletType === "walletconnect" ? await window.web3.eth.getAccounts() : await window.ethereum.request({method: 'eth_requestAccounts'});
            if (accounts.length > 0) {
                const wei = await window.web3.eth.getBalance(accounts[0]);
                const balance = window.web3.utils.fromWei(wei);
                const chainId = await window.web3.eth.getChainId();
                const genesisContract = new window.web3.eth.Contract(GENESIS_ABI, GENESIS_CONTRACT_ADDRESS);
                const toadRunnerzContract = new window.web3.eth.Contract(TOADRUNNERZ_ABI, TOADRUNNERZ_CONTRACT_ADDRESS);
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        connected: true,
                        walletAddress: accounts[0],
                        balance: balance,
                        chainId: chainId,
                        genesisContract: genesisContract,
                        toadRunnerzContract: toadRunnerzContract,
                        walletType: walletType,
                        formattedWalletAddress: accounts[0].substring(0, 6) + "..." + accounts[0].substring(34)
                    }
                });
                if (chainId !== 1) {
                    notify("Switch to Ethereum Mainnet", false, toast.TYPE.ERROR)
                } else {
                    notify('Wallet Connected', 3000, toast.TYPE.SUCCESS)
                }
                /*const res2 = await axios.get("https://www.niftyriver.io/api/platforms/collection/0x1e038A99AAC19162633Dcc4d215E5a27e6eB0355/rarity/?page_size=2000&page=1");

                let i = 0;
                if (res2.status === 200) {
                    while (i < Number(res2.data.results.length)) {
                        const doc = await firebase.firestore().collection("ToadRunnerz").doc(res2.data.results[i].token_id).get();
                        const tokenUri = await toadRunnerzContract.methods.tokenURI(Number(res2.data.results[i].token_id)).call();
                        const data = await axios.get(tokenUri);
                        await doc.ref.set({...res2.data.results[i], ...data.data});
                        console.log(i);
                        i++;
                    }
                }*/
            }
        } catch (e) {
            if (e && e.code && e.code === -32002) {
                notify("Your wallet already has a pending request to sign in", true, toast.TYPE.DEFAULT)
            }
        }
    }

    const notify = (message, autoClose, type) => toast(message, {autoClose, type});

    function getUserInfo() {
        firebase.firestore().collection("Users").doc(user.walletAddress).get().then(user => {
            if (user.exists) {
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        username: user.data().username,
                        fireRef: user.ref,
                    }
                });
            }
        })
    }

    async function getTokens() {
        if (user.chainId === 1) {
            const genesisBalance = await user.genesisContract.methods.balanceOf(user.walletAddress).call();
            const toadrunnerzBalance = await user.toadRunnerzContract.methods.balanceOf(user.walletAddress).call();
            let genesisTokens = [];
            let arcadeClassicsTokens = [];
            let toadRunnerzTokens = [];
            let desertTokens = [];
            let aquaticTokens = [];
            let spaceTokens = [];
            let racingTokens = [];
            let index = 0;
            while (index < genesisBalance) {
                const tokenId = await user.genesisContract.methods.tokenOfOwner(user.walletAddress, index).call();
                if (tokenId !== 4558) {
                    if (tokenId <= 500) {
                        const token = await firebase.firestore().collection("ArcadeGenesis").doc(tokenId.toString()).get();
                        if (token.exists) {
                            genesisTokens.push({...token.data(), tokenId});
                        }
                    } else {
                        const token = await firebase.firestore().collection("ArcadeClassics").doc(tokenId.toString()).get();
                        if (token.exists) {
                            arcadeClassicsTokens.push({...token.data(), tokenId});
                        }
                    }
                }
                index++;
            }
            index = 0;
            while (index < toadrunnerzBalance) {
                const tokenId = await user.toadRunnerzContract.methods.tokenOfOwner(user.walletAddress, index).call();
                const token = await firebase.firestore().collection("ToadRunnerz").doc(tokenId.toString()).get();
                if (token.exists) {
                    toadRunnerzTokens.push({...token.data(), tokenId});
                    if (token.data().name === "Desert") {
                        desertTokens.push({...token.data(), tokenId});
                    }
                    if (token.data().name === "Space") {
                        spaceTokens.push({...token.data(), tokenId});
                    }
                    if (token.data().name === "Racing") {
                        racingTokens.push({...token.data(), tokenId});
                    }
                    if (token.data().name === "Aquatic") {
                        aquaticTokens.push({...token.data(), tokenId});
                    }
                }
                index++;
            }
            setUser(prevUser => {
                return {
                    ...prevUser,
                    toadRunnerzTokens,
                    genesisTokens,
                    arcadeClassicsTokens,
                    desertTokens,
                    spaceTokens,
                    aquaticTokens,
                    racingTokens
                }
            });
        }
    }

    function initListeners() {
        if (isEthereumEnabled()) {
            window.ethereum.on('accountsChanged', (accounts) => {
                if (accounts.length === 0) {
                    window.location.reload();
                } else if (accounts[0] !== user.walletAddress && user.walletAddress !== "") {
                    if (isMetaMaskEnabled()) {
                        initUserWallet("metamask");
                    } else if (isEthereumEnabled()) {
                        initUserWallet('walletlink');
                    }
                }
            });
            window.ethereum.on('chainChanged', (chainId) => {
                window.location.reload();
            });
            window.ethereum.on('disconnect', (providerRpcError) => {
                window.location.reload();
            });
        }
        walletConnectProvider.on("accountsChanged", (accounts) => {
            initUserWallet("walletconnect");
        });
        walletConnectProvider.on("chainChanged", (chainId) => {
            window.location.reload();
        });
        walletConnectProvider.on("disconnect", (code, reason) => {
            walletConnectDisconnect();
        });
        walletConnectProvider.on("connect", () => {
            initUserWallet("walletconnect");
        });
    }

    const walletConnectDisconnect = async () => {
        window.scrollTo(0, 0);
        if (user.walletType === "walletconnect") {
            try {
                //await walletConnectProvider.disconnect();
                window.location.reload();
            } catch (e) {
                console.log(e);
            }
        }
    }

    function isMetaMaskEnabled() {
        if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
            return true;
        }
        return false;
    }

    function isEthereumEnabled() {
        if (typeof window.ethereum !== 'undefined') {
            return true;
        }
        return false;
    }

    useEffect(async () => {
        initListeners();
        if (!MAINTENANCE) {
            if (isMetaMaskEnabled()) {
                initUserWallet("metamask");
            } else if (isEthereumEnabled()) {
                initUserWallet("wallletlink");
            } else if (walletConnectProvider.connected) {
                initUserWallet("walletconnect")
            }
        }

    }, [])

    useEffect(() => {
        if (user.walletAddress) {
            getUserInfo();
            getTokens();
        }
    }, [user.walletAddress]);

    useEffect(() => {
        AOS.init({
            once: true,
            disable: 'phone',
            duration: 750,
            easing: 'ease-out-quart',
        });
    });

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto'
        window.scroll({top: 0})
        document.querySelector('html').style.scrollBehavior = ''
        focusHandling('outline');
    }, [location.pathname]); // triggered on route change

    if (MAINTENANCE) {
        return (
            <div className="flex align-middle justify-center flex-grow">
                <h1 style={{paddingTop: 50}}>Site under maintenance</h1>
            </div>
        );
    }

    return (

        <Switch>
            <Route exact path="/">
                <Play notify={notify} user={user}/>
            </Route>
            <Route path="/leaderboard">
                <Leaderboards notify={notify} user={user}/>
            </Route>
            <Route path="/my-arcades">
                <MyArcades notify={notify} user={user}/>
            </Route>
            <Route path="*">
                <_404 user={user} notify={notify()}/>
            </Route>
        </Switch>

    );

}

export default App;
