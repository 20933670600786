import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Dropdown from '../utils/Dropdown';
import Transition from '../utils/Transition.js';
import Modal from '../utils/Modal';
import Reward from 'react-rewards';


function Header(props) {

    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [connectModalOpen, setConnectModalOpen] = useState(false);
    const trigger = useRef(null);
    const mobileNav = useRef(null);

    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({target}) => {
            if (!mobileNav.current || !trigger.current) return;
            if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({keyCode}) => {
            if (!mobileNavOpen || keyCode !== 27) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    // Handle light modes
    const [darkMode, setDarkMode] = useState(() => {
        const dark = localStorage.getItem('dark-mode');
        if (dark === null) {
            return true;
        } else {
            return dark === 'true';
        }
    });

    useEffect(() => {
        localStorage.setItem('dark-mode', darkMode)
        if (darkMode) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [darkMode]);

    useEffect(() => {
        if (props.triggerOpenConnectModal) {
            setConnectModalOpen(true);
        }
        props.setTriggerOpenConnectModal(false);
    }, [props.triggerOpenConnectModal]);

    return (
        <header className="absolute w-full z-30">
            <div className="max-w-8xl mx-auto px-4 sm:px-6">
                <div className="flex items-center justify-between h-20">

                    {/* Site branding */}
                    <div className="flex-shrink-0 mr-5">
                        {/* Logo */}
                        <Link to="/" className="block" aria-label="ArcadeNFT">
                            <img className="hidden dark:block"
                                 src={require('../assets/images/arcade-animated-play-logo-darkbg.gif').default}
                                 width="100" alt="Logo"/>
                        </Link>
                        <Link to="/" className="block" aria-label="ArcadeNFT">
                            <img className="hidden dark:hidden sm:block"
                                 src={require('../assets/images/arcade-animated-play-logo-whitebg.gif').default}
                                 width="100" alt="Logo"/>
                        </Link>
                    </div>

                    {/* Desktop navigation */}
                    <nav className="hidden md:flex md:flex-grow">

                        {/* Desktop menu links */}
                        <ul className="flex flex-grow flex-wrap items-center font-medium">
                            <li>
                                <Link to="/"
                                      className="text-gray-800 hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Play</Link>
                            </li>
                            <li>
                                <Link to="/leaderboard"
                                      className="text-gray-800 hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Leaderboards</Link>
                            </li>
                            <li>
                                <Link to="/my-arcades"
                                      className="text-gray-800 hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">My
                                    Arcades</Link>
                            </li>
                            {/*<Dropdown title="Tournaments">
                                <li>
                                    <Link to="/tournaments"
                                          className="text-sm text-gray-600 dark:text-gray-400 hover:text-signature-500 dark:hover:text-signature-500 flex py-2 px-4 leading-tight">Genesis
                                        League</Link>
                                </li>
                                <li>
                                    <Link to="/tournaments"
                                          className="text-sm text-gray-600 dark:text-gray-400 hover:text-signature-500 dark:hover:text-signature-500 flex py-2 px-4 leading-tight">Classics
                                        Tour</Link>
                                </li>
                                <li>
                                    <Link to="/tournaments"
                                          className="text-sm text-gray-600 dark:text-gray-400 hover:text-signature-500 dark:hover:text-signature-500 flex py-2 px-4 leading-tight">ToadRunnerz
                                        Qualification</Link>
                                </li>
                            </Dropdown>*/}
                        </ul>

                        {/* Desktop lights switch */}
                        <div className="form-switch focus-within:outline-blue flex flex-col justify-center ml-3">
                            <input type="checkbox" name="light-switch" id="light-switch-desktop"
                                   className="light-switch sr-only" checked={darkMode}
                                   onChange={() => setDarkMode(!darkMode)}/>
                            <label className="relative" htmlFor="light-switch-desktop">
                                <span
                                    className="relative bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-sm z-10"
                                    aria-hidden="true"/>
                                <svg className="absolute inset-0" width="44" height="24" viewBox="0 0 44 24"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g className="fill-current text-gray-900" fillRule="nonzero" opacity=".88">
                                        <path
                                            d="M32 8a.5.5 0 00.5-.5v-1a.5.5 0 10-1 0v1a.5.5 0 00.5.5zM35.182 9.318a.5.5 0 00.354-.147l.707-.707a.5.5 0 00-.707-.707l-.707.707a.5.5 0 00.353.854zM37.5 11.5h-1a.5.5 0 100 1h1a.5.5 0 100-1zM35.536 14.829a.5.5 0 00-.707.707l.707.707a.5.5 0 00.707-.707l-.707-.707zM32 16a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1a.5.5 0 00-.5-.5zM28.464 14.829l-.707.707a.5.5 0 00.707.707l.707-.707a.5.5 0 00-.707-.707zM28 12a.5.5 0 00-.5-.5h-1a.5.5 0 100 1h1a.5.5 0 00.5-.5zM28.464 9.171a.5.5 0 00.707-.707l-.707-.707a.5.5 0 00-.707.707l.707.707z"/>
                                        <circle cx="32" cy="12" r="3"/>
                                        <circle fillOpacity=".4" cx="12" cy="12" r="6"/>
                                        <circle fillOpacity=".88" cx="12" cy="12" r="3"/>
                                    </g>
                                </svg>
                                <span className="sr-only">Switch to light / dark version</span>
                            </label>
                        </div>

                        {/* Desktop CTA on the right */}

                        <ul className="cursor-pointer" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setConnectModalOpen(true);
                        }} className="flex justify-end flex-wrap items-center">
                            <li>
                                <p style={{textAlign: "center", fontSize: 14}}
                                   className="btn-sm text-gray-900 bg-signature-500 hover:bg-signature-400 ml-6">{props.user.walletAddress.length > 0 ? props.user.formattedWalletAddress : "Connect Wallet"}</p>
                            </li>
                        </ul>
                    </nav>

                    {/* Mobile menu */}
                    <div id="mobile-div" className="inline-flex md:hidden">

                        {/* Mobile lights switch */}
                        <div
                            className="form-switch focus-within:outline-blue flex flex-col justify-center mr-6 -mt-0.5">
                            <input type="checkbox" name="light-switch" id="light-switch-mobile"
                                   className="light-switch sr-only" checked={darkMode}
                                   onChange={() => setDarkMode(!darkMode)}/>
                            <label className="relative" htmlFor="light-switch-mobile">
                                <span
                                    className="relative bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-sm z-10"
                                    aria-hidden="true"/>
                                <svg className="absolute inset-0" width="44" height="24" viewBox="0 0 44 24"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g className="fill-current text-gray-900" fillRule="nonzero" opacity=".88">
                                        <path
                                            d="M32 8a.5.5 0 00.5-.5v-1a.5.5 0 10-1 0v1a.5.5 0 00.5.5zM35.182 9.318a.5.5 0 00.354-.147l.707-.707a.5.5 0 00-.707-.707l-.707.707a.5.5 0 00.353.854zM37.5 11.5h-1a.5.5 0 100 1h1a.5.5 0 100-1zM35.536 14.829a.5.5 0 00-.707.707l.707.707a.5.5 0 00.707-.707l-.707-.707zM32 16a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1a.5.5 0 00-.5-.5zM28.464 14.829l-.707.707a.5.5 0 00.707.707l.707-.707a.5.5 0 00-.707-.707zM28 12a.5.5 0 00-.5-.5h-1a.5.5 0 100 1h1a.5.5 0 00.5-.5zM28.464 9.171a.5.5 0 00.707-.707l-.707-.707a.5.5 0 00-.707.707l.707.707z"/>
                                        <circle cx="32" cy="12" r="3"/>
                                        <circle fillOpacity=".4" cx="12" cy="12" r="6"/>
                                        <circle fillOpacity=".88" cx="12" cy="12" r="3"/>
                                    </g>
                                </svg>
                                <span className="sr-only">Switch to light / dark version</span>
                            </label>
                        </div>

                        {/* Hamburger button */}
                        <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`}
                                aria-controls="mobile-nav" aria-expanded={mobileNavOpen}
                                onClick={() => setMobileNavOpen(!mobileNavOpen)}>
                            <span className="sr-only">Menu</span>
                            <svg
                                className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <rect y="4" width="24" height="2" rx="1"/>
                                <rect y="11" width="24" height="2" rx="1"/>
                                <rect y="18" width="24" height="2" rx="1"/>
                            </svg>
                        </button>

                        {/*Mobile navigation */}
                        <Transition
                            show={mobileNavOpen}
                            tag="ul"
                            className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
                            enter="transition ease-out duration-200 transform"
                            enterStart="opacity-0 -translate-x-full"
                            enterEnd="opacity-100 translate-x-0"
                            leave="transition ease-out duration-200"
                            leaveStart="opacity-100"
                            leaveEnd="opacity-0"
                        >
                            <nav ref={mobileNav}
                                 className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar">
                                <div className="py-6 pr-4 pl-20">
                                    {/* Logo */}
                                    <img className="hidden dark:block opacity-50 md:opacity-80"
                                         src={require('../assets/images/arcade-animated-play-logo-darkbg.gif').default}
                                         width="70" alt="logo"/>
                                    <img className="block dark:hidden opacity-50 md:opacity-80"
                                         src={require('../assets/images/arcade-animated-play-logo-whitebg.gif').default}
                                         width="70" alt="logo"/>
                                    {/* Links */}
                                    <ul>
                                        <li>
                                            <Link to="/"
                                                  className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">Play</Link>
                                        </li>
                                        <li>
                                            <Link to="/leaderboard"
                                                  className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">Leaderboards</Link>
                                        </li>
                                        <li>
                                            <Link to="/my-arcades"
                                                  className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">My
                                                Arcades</Link>
                                        </li>
                                        {/*<li className="py-2 my-2 border-t border-b border-gray-200 dark:border-gray-800">
                                            <span
                                                className="flex text-gray-600 dark:text-gray-400 py-2">Tournaments</span>
                                            <ul className="pl-4">
                                                <li>
                                                    <Link to="/tournaments"
                                                          className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Genesis
                                                        League</Link>
                                                </li>
                                                <li>
                                                    <Link to="/tournaments"
                                                          className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Classics
                                                        Tour</Link>
                                                </li>
                                                <li>
                                                    <Link to="/tournaments"
                                                          className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">ToadRunnerz
                                                        Qualification</Link>
                                                </li>
                                            </ul>
                                        </li>*/}
                                    </ul>
                                    <ul style={{marginLeft: -22, marginTop: 10}} className="cursor-pointer" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setConnectModalOpen(true);
                                    }} className="flex flex-wrap items-center">
                                        <li>
                                            <p style={{textAlign: "center", fontSize: 14}}
                                               className="btn-sm text-gray-900 bg-signature-500 hover:bg-signature-400 ml-6">{props.user.walletAddress.length > 0 ? props.user.formattedWalletAddress : "Connect Wallet"}</p>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </Transition>

                    </div>

                </div>
            </div>
            <Modal id="modal" ariaLabel="modal-headline" show={connectModalOpen}
                   handleClose={() => setConnectModalOpen(false)}>
                <div className="relative">
                    <div style={{padding : 10,width: "100%", height: "100%"}}
                         className="flex align-middle flex-grow bg-white dark:bg-gray-900 grid grid-cols-1 sm:grid-cols-3 gap-1">
                        <div onClick={async () => {
                            setConnectModalOpen(false);
                            await props.user.signInWithWalletConnect();
                        }} style={{paddingTop: 50, paddingBottom: 50, alignItems: "center"}}
                             className="flex flex-grow flex-col text-center align-middle justify-center">
                            <img className=""
                                 src={require('../assets/images/walletconnect.png').default} width="100" alt="Logo"/>
                            <p className="hidden sm:block font-medium text-gray-900 dark:text-white">WalletConnect</p>
                            <p className="block sm:hidden font-medium text-gray-900 dark:text-white">WalletConnect/Metamask</p>
                        </div>
                        <div onClick={() => {
                            setConnectModalOpen(false);
                            props.user.signInWithMetamask();
                        }} style={{paddingTop: 50, paddingBottom: 50, alignItems: "center"}}
                             className="hidden sm:flex flex-grow flex-col text-center align-middle justify-center">
                            <img className=""
                                 src={require('../assets/images/metamask.png').default} width="100" alt="Logo"/>
                            <p className="font-medium text-gray-900 dark:text-white">MetaMask</p>
                        </div>
                        <div onClick={async () => {
                            setConnectModalOpen(false);
                            await props.user.signInWithWalletLink();
                        }} style={{paddingTop: 50, paddingBottom: 50, alignItems: "center"}}
                             className="flex flex-grow flex-col text-center align-middle justify-center">
                            <img className=""
                                 src={require('../assets/images/walletlink.png').default} width="100" alt="Logo"/>
                            <p className="font-medium text-gray-900 dark:text-white">Coinbase Wallet</p>
                        </div>
                    </div>
                    {/* {props.user.walletAddress &&<div style={{width: "100%", height: "100%"}}
                                                       className="flex align-middle flex-grow bg-gray-900 grid grid-cols-1 sm:grid-cols-1 gap-1">
                        <div onClick={walletDisconnect} style={{paddingTop: 50, paddingBottom: 50, alignItems: "center"}}
                             className="hidden sm:flex flex-grow flex-col text-center align-middle justify-center">
                            <img className="opacity-50 md:opacity-80"
                                 src={isSignedInWithMetaMask ? require('../images/metamask.png').default : require('../images/walletconnect.png').default} width="100" alt="Logo"/>
                            <p style={{marginBottom : 10}} className="font-bold text-signature-900">{walletAddress.substring(0, 10) + "..." + walletAddress.substring(32)}</p>
                            <p style={{textDecoration : "underline"}} className="font-medium">Sign out</p>
                        </div>
                    </div>} */}
                </div>
            </Modal>
        </header>
    );
}

export default Header;
