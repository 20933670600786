import React, {useEffect, useRef, useState} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Header from '../partials/Header';
import Footer from '../partials/Footer';

function Leaderboards(props) {

    const [triggerOpenConnectModal, setTriggerOpenConnectModal] = useState(false);
    const [desertLeaderboard, setDesertLeaderBoard] = useState([]);
    const [aquaticLeaderboard, setAquaticLeaderBoard] = useState([]);
    const [racingLeaderboard, setRacingLeaderBoard] = useState([]);
    const [spaceLeaderboard, setSpaceLeaderBoard] = useState([]);
    const [coopLeaderboard, setCoopLeaderBoard] = useState([]);
    const [speedRunLeaderboard, setSpeedRunLeaderBoard] = useState([]);
    const [raffleLeaderboard, setRaffleLeaderBoard] = useState([]);
    const [lvl100Leaderboard, setlvl100Leaderboard] = useState([]);
    const [hardcoreLeaderboard, setHardcoreLeaderboard] = useState([]);
    const [countdownLeaderboard, setCountdownLeaderboard] = useState([]);
    const [classicsLeaderboard, setClassicsLeaderboard] = useState([]);
    const [marchmadnessLeaderboard, setMarchmadnessLeaderboard] = useState([]);


    useEffect(async () => {
        const hardcoreLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "HARDCORE").orderBy("score", "desc").get();
        let toReturn = [];
        hardcoreLeaderboard.docs.forEach(doc => {
            const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const minutes = Math.floor((diff / 1000) / 60);
            toReturn.push({minutes, ...doc.data()})
        });
        setHardcoreLeaderboard(toReturn);
    }, []);

    useEffect(async () => {
        const marchmadnessLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "MARCH MADNESS").orderBy("score", "desc").get();
        let toReturn = [];
        let is = 0;
        marchmadnessLeaderboard.docs.forEach(doc => {
            const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const minutes = Math.floor((diff / 1000) / 60);
            //console.log(doc.data().ended)
            const ended = new Date(doc.data().ended);
            const now = new Date("2022-03-31T20:00:00Z");
            //console.log(now.toISOString());
            if(ended.getTime() < now.getTime() && is < 100){
                toReturn.push({minutes, ...doc.data()})
                //console.log(doc.data().walletAddress);
                //console.log(is);
                //is++;
            }
        });
        setMarchmadnessLeaderboard(toReturn);
    }, []);

    useEffect(async () => {
        const classicsLeaderboard = await firebase.firestore().collection("Classics_Highscores").orderBy("score", "desc").get();
        let toReturn = [];
        classicsLeaderboard.docs.forEach(doc => {
            const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const minutes = Math.floor((diff / 1000) / 60);
            toReturn.push({minutes, ...doc.data()})
        });
        setClassicsLeaderboard(toReturn);
    }, []);

    useEffect(async () => {
        const countdownLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "COUNTDOWN").orderBy("score", "desc").get();
        let toReturn = [];
        countdownLeaderboard.docs.forEach(doc => {
            const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const minutes = Math.floor((diff / 1000) / 60);
            toReturn.push({minutes, ...doc.data()})
        });
        setCountdownLeaderboard(toReturn);
    }, []);

    useEffect(async () => {
        const lvl100Leaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "LVL100").orderBy("score", "desc").get();
        let toReturn = [];
        lvl100Leaderboard.docs.forEach(doc => {
            const ms = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const msDate = new Date (ms);
            const min = msDate.getMinutes() < 10 ? "0" + msDate.getMinutes() : msDate.getMinutes();
            const sec = msDate.getSeconds() < 10 ? "0" + msDate.getSeconds() : msDate.getSeconds();
            const millis = msDate.getMilliseconds().toString().length === 2 ? "0" + msDate.getMilliseconds() : msDate.getMilliseconds();
            const minutes = min + ":" + sec + ":" + millis;
            toReturn.push({minutes, ms, ...doc.data()})
        });
        toReturn.sort((a, b) => {
            if(a.level > b.level){
                return -1;
            } else if(b.level > a.level){
                return 1;
            }
            return a.ms - b.ms;
        });
        setlvl100Leaderboard(toReturn);
    }, []);

    useEffect(async () => {
        const racingLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "Racing").orderBy("score", "desc").get();
        let toReturn = [];
        racingLeaderboard.docs.forEach(doc => {
            const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const minutes = Math.floor((diff / 1000) / 60);
            toReturn.push({minutes, ...doc.data()})
        });
        setRacingLeaderBoard(toReturn);
    }, []);

    useEffect(async () => {
        const aquaticLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "Aquatic").orderBy("score", "desc").get();
        let toReturn = [];
        aquaticLeaderboard.docs.forEach(doc => {
            const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const minutes = Math.floor((diff / 1000) / 60);
            toReturn.push({minutes, ...doc.data()})
        });
        setAquaticLeaderBoard(toReturn);
    }, []);

    useEffect(async () => {
        const spaceLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "Space").orderBy("score", "desc").get();
        let toReturn = [];
        spaceLeaderboard.docs.forEach(doc => {
            const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const minutes = Math.floor((diff / 1000) / 60);
            toReturn.push({minutes, ...doc.data()})
        });
        setSpaceLeaderBoard(toReturn);
    }, []);

    useEffect(async () => {
        const desertLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "Desert").orderBy("score", "desc").get();
        let toReturn = [];
        desertLeaderboard.docs.forEach(doc => {
            const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const minutes = Math.floor((diff / 1000) / 60);
            toReturn.push({minutes, ...doc.data()})
        });
        setDesertLeaderBoard(toReturn);
    }, []);

    useEffect(async () => {
        const coopLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "Co-Op").orderBy("score", "desc").get();
        let toReturn = [];
        coopLeaderboard.docs.forEach(doc => {
            const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const minutes = Math.floor((diff / 1000) / 60);
            toReturn.push({minutes, ...doc.data()})
        });
        setCoopLeaderBoard(toReturn);
    }, []);

    useEffect(async () => {

        const speedRunLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").where("mode", "==", "SpeedRun").where("level", "==", 10).get();
        let toReturn = [];
        speedRunLeaderboard.docs.forEach(doc => {
            const ms = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
            const msDate = new Date (ms);
            const min = msDate.getMinutes() < 10 ? "0" + msDate.getMinutes() : msDate.getMinutes();
            const sec = msDate.getSeconds() < 10 ? "0" + msDate.getSeconds() : msDate.getSeconds();
            const millis = msDate.getMilliseconds().toString().length === 2 ? "0" + msDate.getMilliseconds() : msDate.getMilliseconds();
            const minutes = min + ":" + sec + ":" + millis;
            toReturn.push({minutes, ms, ...doc.data()})
        });
        toReturn.sort((a, b) => {
            return a.ms - b.ms;
        });
        setSpeedRunLeaderBoard(toReturn);
    }, []);

    useEffect(async () => {
        /*const raffleLeaderboard = await firebase.firestore().collection("ToadRunnerz_Highscores").get();
        let toReturn = [];
        raffleLeaderboard.docs.forEach(doc => {
            if (doc.data().mode !== "Co-Op") {
                const diff = Math.abs(new Date(doc.data().started) - new Date(doc.data().ended));
                const minutes = Math.floor((diff / 1000) / 60);
                toReturn.push({minutes, ...doc.data()});
            }
        });
        setRaffleLeaderBoard(toReturn);*/
    }, []);

    function getPositionFromIndex(index) {
        let position = "🏅 " + (index + 1).toString();
        if (index === 0) {
            position = "🏆 1";
        }
        if (index === 1) {
            position = "🥈 2";
        }
        if (index === 2) {
            position = "🥉 3";
        }
        return position;
    }

    function getAmountOverLevel(arr, minLevel) {
        let amount = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].level > minLevel) {
                amount++;
            }
        }
        return amount;
    }

    function getWhitelistEligibility(arr) {
        let toReturn = false;
        if (props && props.user && props.user.connected) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].walletAddress === props.user.walletAddress && arr[i].position >= 50) {
                    toReturn = true;
                }
            }
        }
        return toReturn;
    }

    function getMarchPrizeFromIndex(index) {
        let prize = "-";
        if (index < 100) {
            prize = "TOP 100 RAFFLE"
        }
        if (index < 25) {
            prize = "TOP 25 RAFFLE"
        }
        if (index === 0) {
            prize = "MetaDragonz free mint";
        }
        if (index === 24) {
            prize = "MetaDragonz free mint";
        }
        if (index === 41) {
            prize = "MetaDragonz free mint";
        }
        if (index === 68) {
            prize = "MetaDragonz free mint";
        }
        if (index === 99) {
            prize = "MetaDragonz free mint";
        }
        return prize;
    }

    function getPrizeFromIndex(index) {
        let prize = "-";
        if (index === 0) {
            prize = "2 ETH";
        } else if (index === 1) {
            prize = "1 ETH";
        } else if (index === 2) {
            prize = "0.5 ETH";
        } else if (index === 3) {
            prize = "0.4 ETH";
        } else if (index === 4) {
            prize = "0.3 ETH";
        } else if (index === 5) {
            prize = "0.2 ETH";
        } else if (index === 6) {
            prize = "0.1 ETH";
        } else if (index < 10) {
            prize = "Airdrop";
        }
        return prize;
    }

    function getAirdropsPrizeFromIndex(index) {
        let prize = "-";
        if (index === 0) {
            prize = "Arcade Classic + ToadRunner";
        } else if (index === 1) {
            prize = "Arcade Classic";
        } else if (index === 2) {
            prize = "Arcade Classic";
        } else if (index === 3) {
            prize = "ToadRunner";
        } else if (index === 4) {
            prize = "ToadRunner";
        }
        return prize;
    }

    function getAirdropsPrizeFromIndex2(index) {
        let prize = "-";
        if (index === 0) {
            prize = "Airdrop Next Drop + 2x ToadRunner";
        } else if (index === 1) {
            prize = "Airdrop Next Drop + 1x ToadRunner";
        } else if (index === 2) {
            prize = "Airdrop Next Drop";
        } else if (index === 3) {
            prize = "ToadRunner";
        } else if (index === 4) {
            prize = "ToadRunner";
        }
        return prize;
    }

    function getPrizeFromHardcoreTourney(index) {
        let prize = "-";
        if (index === 0) {
            prize = "0.5 ETH + Hambody NFT";
        } else if (index === 1) {
            prize = "0.2 ETH";
        } else if (index === 2) {
            prize = "Airdrop Next Drop";
        } else if (index === 3) {
            prize = "ToadRunnerz Airdrop";
        } else if (index === 4) {
            prize = "ToadRunnerz Airdrop";
        }
        return prize;
    }

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header setTriggerOpenConnectModal={setTriggerOpenConnectModal}
                    triggerOpenConnectModal={triggerOpenConnectModal} {...props} />

            {/*  Page content */}
            <main className="flex-grow">

                <section id="marchmadness" style={{marginTop: 140}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>March Madness <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                            <p style={{fontSize: 13, color : "grey", marginLeft : 2}}>Ending 31st March</p>
                            <p style={{fontSize: 13, color : "grey", marginLeft : 2}}>$2500 + 24NFTs RAFFLE</p>
                        </h2>
                        <img style={{cursor : "pointer"}} onClick={() => window.location = "https://app.arcadenfts.com/"} width="140" height="auto" alt="Countdown" className="block h-auto"
                             src={require('../assets/click_to_play.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-left">Score</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {marchmadnessLeaderboard.map((leader, index) => {
                                        if (index < 100) {
                                            const position = getPositionFromIndex(index);
                                            if (leader.walletAddress === props.user.walletAddress) {
                                                return (
                                                    <tr key={index + leader.walletAddress}
                                                        className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                        <td className="p-3">
                                                            {position}
                                                        </td>
                                                        <td className="p-3">
                                                            {getMarchPrizeFromIndex(index)}
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.level}
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.minutes}&nbsp;min
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.score}
                                                        </td>
                                                        <td className="p-3 text-right">
                                                            {leader.walletAddress}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {getMarchPrizeFromIndex(index)}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    {marchmadnessLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {getMarchPrizeFromIndex(index)}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="classics" style={{marginTop: 140}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>Classics <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                            <p style={{fontSize: 13, color : "grey", marginLeft : 2}}>Place top 100 and earn whitelist to MetaDragonz drop</p>
                        </h2>
                        <img width="140" height="auto" alt="Countdown" className="block h-auto"
                             src={require('../assets/coin_boi-2.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-left">Score</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {classicsLeaderboard.map((leader, index) => {
                                        if (index < 100) {
                                            const position = getPositionFromIndex(index);
                                            if (leader.walletAddress === props.user.walletAddress) {
                                                return (
                                                    <tr key={index + leader.walletAddress}
                                                        className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                        <td className="p-3">
                                                            {position}
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.position <= 100 ? "Whitelist" : ""}
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.level}
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.minutes}&nbsp;min
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.score}
                                                        </td>
                                                        <td className="p-3 text-right">
                                                            {leader.walletAddress}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        Whitelist
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    {classicsLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.position <= 100 ? "Whitelist" : ""}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="countdown" style={{marginTop: 140}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>Countdown <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                            <p style={{fontSize: 13, color : "grey", marginLeft : 2}}>Place top 100 and earn whitelist to MetaDragonz drop</p>
                        </h2>
                        <img width="140" height="auto" alt="Countdown" className="block h-auto"
                             src={require('../assets/images/metadragonz_banner_logo.0ec03f05.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-left">Score</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {countdownLeaderboard.map((leader, index) => {
                                        if (index < 100) {
                                            const position = getPositionFromIndex(index);
                                            const prize = getPrizeFromHardcoreTourney(index);
                                            if (leader.walletAddress === props.user.walletAddress) {
                                                return (
                                                    <tr key={index + leader.walletAddress}
                                                        className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                        <td className="p-3">
                                                            {position}
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.position <= 100 ? "Whitelist" : ""}
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.level}
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.minutes}&nbsp;min
                                                        </td>
                                                        <td className="p-3">
                                                            {leader.score}
                                                        </td>
                                                        <td className="p-3 text-right">
                                                            {leader.walletAddress}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        Whitelist
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    {countdownLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.position <= 100 ? "Whitelist" : ""}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="hardcore" style={{marginTop: 140}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>Hardcore <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                        </h2>
                        <img width="140" height="auto" alt="Hardcore" className="block h-auto"
                             src={require('../assets/images/sticker_3.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-left">Score</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {hardcoreLeaderboard.map((leader, index) => {
                                        if (index < 5) {
                                            const position = getPositionFromIndex(index);
                                            const prize = getPrizeFromHardcoreTourney(index);
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    {hardcoreLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        const prize = getPrizeFromHardcoreTourney(index);
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="lvl100" style={{marginTop: 50}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>LVL 100 <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                        </h2>
                        <img width="140" height="auto" alt="Racing" className="block h-auto"
                             src={require('../assets/images/sticker_lvl100.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lvl100Leaderboard.map((leader, index) => {
                                        if (index < 5) {
                                            const position = getPositionFromIndex(index);
                                            const prize = getAirdropsPrizeFromIndex2(index)
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    {lvl100Leaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        const prize = getAirdropsPrizeFromIndex2(index)
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="speedRun" style={{marginTop: 50}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>SpeedRun <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                        </h2>
                        <img width="140" height="auto" alt="SpeedRun" className="block h-auto"
                             src={require('../assets/images/sticker.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {speedRunLeaderboard.map((leader, index) => {
                                        if (index < 5) {
                                            const position = getPositionFromIndex(index);
                                            const prize = getAirdropsPrizeFromIndex(index);
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}<br/>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                    {speedRunLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        const prize = getAirdropsPrizeFromIndex(index);
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}<br/>
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="coop" style={{marginTop: 50}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>Co-Op <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                        </h2>
                        <img width="140" height="auto" alt="Aquatic" className="block h-auto"
                             src={require('../assets/images/toad_sticker_5.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-left">Score</th>
                                        <th className="p-3 text-right">Addresses</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {coopLeaderboard.map((leader, index) => {
                                        if (index < 5) {
                                            const position = getPositionFromIndex(index);
                                            const prize = getAirdropsPrizeFromIndex(index);
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddresses[0].substring(0, 10) + "..." + leader.walletAddresses[0].substring(32)}<br/>
                                                        {leader.walletAddresses[1].substring(0, 10) + "..." + leader.walletAddresses[1].substring(32)}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                    {coopLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        const prize = getAirdropsPrizeFromIndex(index);
                                        if (leader.walletAddresses[0] === props.user.walletAddress || leader.walletAddresses[1] === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddresses[0].substring(0, 10) + "..." + leader.walletAddresses[0].substring(32)}<br/>
                                                        {leader.walletAddresses[1].substring(0, 10) + "..." + leader.walletAddresses[1].substring(32)}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="racing" style={{marginTop: 50}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>Racing Standard <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                        </h2>
                        <img width="140" height="auto" alt="Racing" className="block h-auto"
                             src={require('../assets/images/racing.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-left">Score</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {racingLeaderboard.map((leader, index) => {
                                        if (index < 10) {
                                            const position = getPositionFromIndex(index);
                                            const prize = getPrizeFromIndex(index);
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    {racingLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        const prize = getPrizeFromIndex(index);
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="desert" style={{marginTop: 50}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>Desert Standard <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                        </h2>                        <img width="140" height="auto" alt="Desert" className="block h-auto"
                             src={require('../assets/images/desert.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-left">Score</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {desertLeaderboard.map((leader, index) => {
                                        if (index < 10) {
                                            const position = getPositionFromIndex(index);
                                            const prize = getPrizeFromIndex(index);
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    {desertLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        const prize = getPrizeFromIndex(index);
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="aquatic" style={{marginTop: 50}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>Aquatic Standard <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                        </h2>                        <img width="140" height="auto" alt="Aquatic" className="block h-auto"
                             src={require('../assets/images/aquatic.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-left">Score</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {aquaticLeaderboard.map((leader, index) => {
                                        if (index < 10) {
                                            const position = getPositionFromIndex(index);
                                            const prize = getPrizeFromIndex(index);
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                    })}
                                    {aquaticLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        const prize = getPrizeFromIndex(index);
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="space" style={{marginTop: 50, paddingBottom: 120}} className="relative">
                    <div style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                        <h2 style={{fontSize: 26}}>Space Standard <br/>
                            <span style={{fontSize: 16, fontStyle : "italic"}}>Tournament Ended</span>
                        </h2>                        <img width="140" height="auto" alt="Aquatic" className="block h-auto"
                             src={require('../assets/images/space.png').default}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                            <div style={{width: "100%"}} className="overflow-auto lg:overflow-visible ">
                                <table style={{width: "100%"}}
                                       className="table text-gray-400 border-separate space-y-6 text-sm">
                                    <thead style={{fontSize: 15}}
                                           className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100">
                                    <tr>
                                        <th className="p-3 text-left">Position</th>
                                        <th className="p-3 text-left">Prize</th>
                                        <th className="p-3 text-left">Level</th>
                                        <th className="p-3 text-left">Round Time</th>
                                        <th className="p-3 text-left">Score</th>
                                        <th className="p-3 text-right">Address</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {spaceLeaderboard.map((leader, index) => {
                                        if (index < 10) {
                                            const position = getPositionFromIndex(index);
                                            const prize = getPrizeFromIndex(index);
                                            return (
                                                <tr key={leader.walletAddress + index}
                                                    className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress.substring(0, 10) + "..." + leader.walletAddress.substring(32)}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                    {spaceLeaderboard.map((leader, index) => {
                                        const position = getPositionFromIndex(index);
                                        const prize = getPrizeFromIndex(index);
                                        if (leader.walletAddress === props.user.walletAddress) {
                                            return (
                                                <tr key={index + leader.walletAddress}
                                                    className="bg-signature-900 text-gray-900 dark:text-gray-900">
                                                    <td className="p-3">
                                                        {position}
                                                    </td>
                                                    <td className="p-3">
                                                        {prize}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.level}
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.minutes}&nbsp;min
                                                    </td>
                                                    <td className="p-3">
                                                        {leader.score}
                                                    </td>
                                                    <td className="p-3 text-right">
                                                        {leader.walletAddress}
                                                    </td>
                                                </tr>

                                            );
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default Leaderboards;
