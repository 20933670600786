import React, {useEffect, useRef, useState} from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Modal from "../utils/Modal";
import {toast} from 'react-toastify';
import GameModal from "../utils/GameModal";

export function Play(props) {

    const [triggerOpenConnectModal, setTriggerOpenConnectModal] = useState(false);
    const [coopModalOpen, setCoopModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [coopHost, setCoopHost] = useState(false);
    const [gameModalOpen, setGameModalOpen] = useState(false);
    const [selectedToken, setSelectedToken] = useState(() => {
        return null;
    })
    const [gameMode, setGameMode] = useState(() => {
        return {
            mode: null,
            tokens: [],
            room: null,
        };
    });

    const playGame = (mode) => {
        let tokens = [];
        if (mode === "Desert") {
            tokens = props.user.desertTokens;
        }
        if (mode === "Racing") {
            tokens = props.user.racingTokens;
        }
        if (mode === "Space") {
            tokens = props.user.spaceTokens;
        }
        if (mode === "Aquatic") {
            tokens = props.user.aquaticTokens;
        }
        if (mode === "CLASSICS") {
            tokens = props.user.arcadeClassicsTokens;
        }
        if (mode === "Co-Op" || mode === "SpeedRun" || mode === "LVL100" || mode === "HARDCORE" || mode === "COUNTDOWN" || mode === "MARCH MADNESS") {
            tokens = props.user.aquaticTokens.concat(props.user.spaceTokens, props.user.racingTokens, props.user.desertTokens);
        }
        if (tokens.length) {
            setGameMode({mode: mode, tokens: tokens, room: mode === "Co-Op" ? null : generateId()});
        } else {
            props.notify("You need to own a " + (mode === "MARCH MADNESS" || mode === "Co-Op" || mode === "SpeedRun" || mode === "LVL100" || mode === "HARDCORE" || mode === "COUNTDOWN" ? "" : mode) + " ToadRunnerz to enter this league", 2500, toast.TYPE.DEFAULT);
        }
    }

    function generateId() {
        let firstPart = (Math.random() * 46656) | 0;
        let secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        return Math.floor(Math.random() * 2) + firstPart + secondPart;
    }

    function startGame() {
        setGameModalOpen(true);
        window.mode = gameMode.mode;
        window.tokenId = selectedToken === null ? gameMode.tokens[0].tokenId : selectedToken;
        let url = "";
        if (gameMode.mode === "CLASSICS") {
            for (let i = 0; i < props.user.arcadeClassicsTokens.length; i++) {
                if (props.user.arcadeClassicsTokens[i].tokenId === window.tokenId) {
                    url = props.user.arcadeClassicsTokens[i].animation_url;
                }
            }
        }
        window.animationURL = url;
        window.walletAddress = props.user.walletAddress;
        window.dyno = gameMode.room.charAt(0);
        window.room = gameMode.room;
        setTimeout(() => {
            if (window.mode === "SpeedRun") {
                document.getElementById("speedrunnerz").contentWindow.initGamePlay();
            } else if (window.mode === "LVL100") {
                document.getElementById("lvl100").contentWindow.initGamePlay();
            } else if (window.mode === "COUNTDOWN") {
                document.getElementById("countdown").contentWindow.initGamePlay();
            } else if (window.mode === "HARDCORE") {
                document.getElementById("hardcore").contentWindow.initGamePlay();
            } else if (window.mode === "MARCH MADNESS") {
                document.getElementById("marchmadness").contentWindow.initGamePlay();
            } else if (window.mode === "CLASSICS") {
                let w = document.getElementById("classics");
                if (w) {
                    //document.getElementById("classics").contentWindow.initGamePlay();
                }
            } else {
                document.getElementById("toadrunnerz").contentWindow.initGamePlay();
            }
        }, 3000);
    }

    const closeGame = () => {
        if (window.mode === "SpeedRun") {
            document.getElementById("speedrunnerz").contentWindow.quitGamePlay();
        } else if (window.mode === "HARDCORE") {
            document.getElementById("hardcore").contentWindow.quitGamePlay();
        } else if (window.mode === "COUNTDOWN") {
            document.getElementById("countdown").contentWindow.quitGamePlay();
        } else if (window.mode === "LVL100") {
            document.getElementById("lvl100").contentWindow.quitGamePlay();
        } else if (window.mode === "MARCH MADNESS") {
            document.getElementById("marchmadness").contentWindow.quitGamePlay();
        } else if (window.mode === "CLASSICS") {
            document.getElementById("classics").contentWindow.quitGamePlay();
        } else {
            document.getElementById("toadrunnerz").contentWindow.quitGamePlay();
        }
        setGameModalOpen(false);
        //setGameMode(null);
    }

    useEffect(() => {
        if (gameMode && gameMode.mode) {
            if (gameMode.mode === "Co-Op") {
                setCoopModalOpen(true);
            } else {
                setModalOpen(true);
            }
        }
    }, [gameMode])

    useEffect(() => {
        if (!modalOpen && !coopModalOpen) {
            //setGameMode({mode: null, tokens: [], room: null});
        }
    }, [modalOpen, coopModalOpen])

    function createIframe() {
        if (gameModalOpen && gameMode.mode === "SpeedRun") {
            return {__html: '<iframe id="speedrunnerz" width="100%" height="100%" src="speedrun.html" style=""></iframe>'}
        } else if (gameModalOpen && gameMode.mode === "LVL100") {
            return {__html: '<iframe id="lvl100" width="100%" height="100%" src="lvl100.html" style=""></iframe>'}
        } else if (gameModalOpen && gameMode.mode === "HARDCORE") {
            return {__html: '<iframe id="hardcore" width="100%" height="100%" src="hardcore.html" style=""></iframe>'}
        } else if (gameModalOpen && gameMode.mode === "COUNTDOWN") {
            return {__html: '<iframe id="countdown" width="100%" height="100%" src="countdown.html" style=""></iframe>'}
        } else if (gameModalOpen && gameMode.mode === "CLASSICS") {
            return {__html: '<iframe id="classics" width="100%" height="100%" src="/classics/classics.html" style=""></iframe>'}
        } else if (gameModalOpen && gameMode.mode === "MARCH MADNESS") {
            return {__html: '<iframe id="marchmadness" width="100%" height="100%" src="marchmadness.html" style=""></iframe>'}
        } else if (gameModalOpen && gameMode.mode !== "SpeedRun") {
            return {__html: '<iframe id="toadrunnerz" width="100%" height="100%" src="toadrunnerz.html" style=""></iframe>'}
        }
        return {__html: '<div></div>'}
    }

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header setTriggerOpenConnectModal={setTriggerOpenConnectModal}
                    triggerOpenConnectModal={triggerOpenConnectModal} {...props} />
            {/*  Page content */}
            <main style={{marginTop: 120}} className="flex-grow">
                <section style={{alignItems: "center"}}
                         className="relative container my-1 mx-auto px-4 md:px-12 flex-row flex align-middle justify-between">
                    <h2 style={{fontSize: 20}}>Select Game Mode</h2>
                    <img width="120" height="auto" alt="Racing" className="block h-auto"
                         src={require('../assets/images/toadrunnerz_tournament.png').default}/>
                </section>
                {/*<section className="relative">
                    <div className="container my-12 mx-auto px-4 md:px-12">
                        <h1>Live Tournaments</h1>
                        <div className="flex flex-wrap -mx-1 lg:-mx-4">

                        </div>
                    </div>
                </section>*/}
                <section className="relative">
                    <div className="container my-12 mx-auto px-4 md:px-12">
                        <h2>Previous Tournaments</h2>
                        <div className="flex flex-wrap -mx-1 lg:-mx-4">

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("MARCH MADNESS");
                                    }}>
                                        <img alt="Classics" className="block h-auto w-full"
                                             src={require("../assets/marchmadness.png").default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/arcadenft?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Drop&search[stringTraits][0][values][0]=Arcade%20Classics">
                                                MARCH<br />MADNESS
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 12}} className="">
                                            🏆 $2500 + 24NFTs
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.toadRunnerzTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own at least
                                            1
                                            Arcade Classics</p>}
                                        {props.user.connected && props.user.toadRunnerzTokens.length > 0 &&
                                        <p onClick={() => playGame("MARCH MADNESS")}
                                           className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.connected && props.user.toadRunnerzTokens.length === 0 &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#marchmadness"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>

                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("CLASSICS");
                                    }}>
                                        <img alt="Classics" className="block h-auto w-full"
                                             src={require("../assets/Skiss-Poster.jpg").default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/arcadenft?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Drop&search[stringTraits][0][values][0]=Arcade%20Classics">
                                                CLASSICS
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 Whitelists
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.arcadeClassicsTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own at least
                                            1
                                            Arcade Classics</p>}
                                        {props.user.connected && (props.user.arcadeClassicsTokens.length > 0) &&
                                        <p onClick={() => playGame("CLASSICS")}
                                           className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.connected && (props.user.arcadeClassicsTokens.length > 0) &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#classics"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>

                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("COUNTDOWN");
                                    }}>
                                        <img alt="SpeedRun" className="block h-auto w-full"
                                             src={require('../assets/images/metadragonz_hero_bg.png').default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/toadrunnerz?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Edition&search[stringTraits][0][values][0]=Racing">
                                                COUNTDOWN
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 Whitelists
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.spaceTokens.length === 0 && props.user.desertTokens.length === 0 && props.user.racingTokens.length === 0 && props.user.aquaticTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own at least
                                            1
                                            ToadRunnerz</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <p onClick={() => playGame("COUNTDOWN")}
                                           className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#countdown"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>

                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("HARDCORE");
                                    }}>
                                        <img alt="SpeedRun" className="block h-auto w-full"
                                             src={require('../assets/images/sticker_3.png').default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/toadrunnerz?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Edition&search[stringTraits][0][values][0]=Racing">
                                                HARDCORE
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 0.7 ETH & Airdrops
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.spaceTokens.length === 0 && props.user.desertTokens.length === 0 && props.user.racingTokens.length === 0 && props.user.aquaticTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own at least
                                            1
                                            ToadRunnerz</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <p onClick={() => playGame("HARDCORE")}
                                           className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#hardcore"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>

                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("LVL100");
                                    }}>
                                        <img alt="SpeedRun" className="block h-auto w-full"
                                             src={require('../assets/images/sticker_lvl100.jpg').default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/toadrunnerz?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Edition&search[stringTraits][0][values][0]=Racing">
                                                LVL100
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 Airdrops
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.spaceTokens.length === 0 && props.user.desertTokens.length === 0 && props.user.racingTokens.length === 0 && props.user.aquaticTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own at least
                                            1
                                            ToadRunnerz</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <p onClick={() => playGame("LVL100")}
                                           className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#lvl100"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>

                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("SpeedRun");
                                    }}>
                                        <img alt="SpeedRun" className="block h-auto w-full"
                                             src={require('../assets/images/sticker.png').default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/toadrunnerz?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Edition&search[stringTraits][0][values][0]=Racing">
                                                SpeedRun
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 Airdrops
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.spaceTokens.length === 0 && props.user.desertTokens.length === 0 && props.user.racingTokens.length === 0 && props.user.aquaticTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own at least
                                            1
                                            ToadRunnerz</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <p onClick={() => playGame("SpeedRun")}
                                           className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#speedrun"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>

                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article onClick={(e) => {
                                    playGame('Co-Op')
                                }} className="overflow-hidden rounded-lg shadow-lg">

                                    <div>
                                        <img alt="Space" className="block h-auto w-full"
                                             src={require('../assets/images/toad_sticker_5.png').default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/toadrunnerz">
                                                Co-Op
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 Airdrops
                                        </p>
                                    </header>
                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.spaceTokens.length === 0 && props.user.desertTokens.length === 0 && props.user.racingTokens.length === 0 && props.user.aquaticTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own at least
                                            1
                                            ToadRunnerz</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <p onClick={() => playGame("Co-Op")}
                                           className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.connected && (props.user.spaceTokens.length > 0 || props.user.desertTokens.length > 0 || props.user.racingTokens.length > 0 || props.user.aquaticTokens.length > 0) &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#coop"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>
                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("Racing");
                                    }}>
                                        <img alt="Racing" className="block h-auto w-full"
                                             src={require('../assets/images/racing_card.png').default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/toadrunnerz?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Edition&search[stringTraits][0][values][0]=Racing">
                                                Racing Standard
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 6.5 ETH
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.racingTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own a Racing
                                            ToadRunnerz</p>}
                                        {props.user.racingTokens.length > 0 && <p onClick={() => playGame("Racing")}
                                                                                  className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.racingTokens.length > 0 &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#racing"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>

                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("Desert");
                                    }}>
                                        <img alt="Desert" className="block h-auto w-full"
                                             src={require('../assets/images/desert_card.png').default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/toadrunnerz?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Edition&search[stringTraits][0][values][0]=Desert">
                                                Desert Standard
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 6.5 ETH
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.desertTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own a Desert
                                            ToadRunnerz</p>}
                                        {props.user.desertTokens.length > 0 && <p onClick={() => playGame("Desert")}
                                                                                  className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.desertTokens.length > 0 &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#desert"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>

                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("Aquatic");
                                    }}>
                                        <img alt="Aquatic" className="block h-auto w-full"
                                             src={require('../assets/images/aquatic_card.png').default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/toadrunnerz?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Edition&search[stringTraits][0][values][0]=Aquatic">
                                                Aquatic Standard
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 6.5 ETH
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.aquaticTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own a Aquatic
                                            ToadRunnerz</p>}
                                        {props.user.aquaticTokens.length > 0 && <p onClick={() => playGame("Aquatic")}
                                                                                   className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.aquaticTokens.length > 0 &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#aquatic"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>

                                </article>

                            </div>

                            <div className="flex my-1 px-1 w-full h-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                                <article className="cursor-pointer overflow-hidden rounded-lg shadow-lg">

                                    <div onClick={(e) => {
                                        playGame("Space");
                                    }}>
                                        <img alt="Space" className="block h-auto w-full"
                                             src={require('../assets/images/space_card.png').default}/>
                                    </div>

                                    <header
                                        className="bg-white dark:bg-gray-800 flex items-center justify-between leading-tight p-3">
                                        <h1 className="text-lg">
                                            <a target="_blank"
                                               className="no-underline hover:underline text-gray-900 dark:text-white"
                                               href="https://opensea.io/collection/toadrunnerz?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Edition&search[stringTraits][0][values][0]=Space">
                                                Space Standard
                                            </a>
                                        </h1>
                                        <p style={{color: "gold", fontSize: 14}} className="">
                                            🏆 6.5 ETH
                                        </p>
                                    </header>

                                    <footer className="bg-white dark:bg-gray-800 flex items-center leading-none p-3">
                                        {!props.user.connected && <p onClick={() => setTriggerOpenConnectModal(true)}
                                                                     className="cursor-pointer font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full hover:bg-gray-700 bg-gray-500 bg-opacity-50 transition duration-150 ease-in-out">Sign
                                            in to play</p>}
                                        {props.user.connected && props.user.spaceTokens.length === 0 &&
                                        <p style={{fontSize: 11}}
                                           className="cursor-pointer text-xs font-medium text-gray-900 dark:text-white py-2">You
                                            are not eligible to compete in this mode. Participants need to own a Space
                                            ToadRunnerz</p>}
                                        {props.user.spaceTokens.length > 0 && <p onClick={() => playGame("Space")}
                                                                                 className="cursor-pointer font-medium text-center text-gray-900 py-2 px-3 rounded-full bg-signature-900 hover:bg-signature-700 transition duration-150 ease-in-out">Play</p>}
                                        {props.user.spaceTokens.length > 0 &&
                                        <a href={"https://app.arcadenfts.com/leaderboard#space"}
                                           className="cursor-pointer mx-3 font-medium text-center text-gray-900 dark:text-white py-2 px-3 rounded-full bg-gray-500 hover:bg-gray-700 transition bg-opacity-50 duration-150 ease-in-out">Leaderboard</a>}
                                        <p></p>
                                    </footer>
                                </article>
                            </div>

                        </div>
                    </div>
                </section>
            </main>

            {/*  Site footer */}
            <Footer/>

            <Modal id="modal" ariaLabel="modal-headline" show={modalOpen}
                   handleClose={() => setModalOpen(false)}>
                <div className="mx-auto py-5 px-4">
                    <h2 style={{paddingTop: 20, paddingBottom: 20}} className="text-signature-900 text-center">Select
                        TokenId to play with</h2>
                    <div className="relative inline-block w-full text-gray-700">
                        <select
                            onChange={(e) => {
                                setSelectedToken(e.target.value);
                            }}
                            className="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline"
                            placeholder="Regular input">
                            {gameMode.tokens.map(token => <option key={token.tokenId}>{token.tokenId}</option>)}
                        </select>
                    </div>
                    <ul style={{marginTop: 10}} className="cursor-pointer" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setModalOpen(false);
                        startGame();
                    }} className="flex justify-end flex-wrap items-center">
                        <li style={{width: "100%"}}>
                            <p style={{width: "100%", textAlign: "center", fontSize: 14}}
                               className="btn-sm text-gray-900 bg-signature-500 hover:bg-signature-400">Start
                                Playing</p>
                        </li>
                    </ul>
                </div>
            </Modal>

            <Modal id="coopModal" ariaLabel="modal-headline" show={coopModalOpen}
                   handleClose={() => setCoopModalOpen(false)}>
                <div className="mx-auto py-5 px-4">
                    <div style={{padding: 10, width: "100%", height: "100%"}}
                         className="flex align-middle flex-grow grid grid-cols-2 sm:grid-cols-2 gap-2">
                        <div onClick={() => {
                            setCoopHost(false);
                            setGameMode({...gameMode, room: null})
                        }} style={{alignItems: "center", border: coopHost ? "0px" : "2px solid white"}}
                             className="flex flex-grow flex-col py-3 text-center mx-3 align-middle justify-center">
                            <img className="py-2"
                                 src={require('../assets/images/join_game.png').default} width="50" alt="JoinGame"/>
                            <p className="font-medium text-white">Join Game</p>
                        </div>
                        <div onClick={() => {
                            setCoopHost(true);
                            setGameMode({...gameMode, room: generateId()})
                        }} style={{alignItems: "center", border: coopHost ? "2px solid white" : "0px"}}
                             className="flex flex-grow flex-col py-3 text-center mx-3 align-middle justify-center">
                            <img className="py-2"
                                 src={require('../assets/images/host_game.png').default} width="50" alt="JoinGame"/>
                            <p className="font-medium text-white">Host Game</p>
                        </div>
                    </div>
                    {coopHost && <div style={{padding: 30, width: "100%", height: "100%"}}
                                      className="flex align-middle justify-center flex-grow text-center">
                        <h2 className="text-lg font-medium text-signature-900 text-center">Game
                            ID:&nbsp;{gameMode.room}</h2>
                    </div>}
                    {!coopHost && <div style={{padding: 30, width: "100%", height: "100%"}}
                                       className="flex align-middle justify-center flex-grow text-center">
                        {/* Game ID Form */}
                        <form className="max-w-xl mx-auto">
                            <div className="flex flex-wrap">
                                <div className="w-full px-3">
                                    <label className="block text-signature-900 font-medium mb-1" htmlFor="roomId">Join
                                        Co-Op <span className="text-red-600">*</span></label>
                                    <input onInput={(e) => setGameMode({...gameMode, room: e.target.value})} id="roomId"
                                           type="text" className="form-input w-full" placeholder="Game ID" required/>
                                </div>
                            </div>
                        </form>
                    </div>}
                    <h2 style={{paddingTop: 10, paddingBottom: 10}} className="text-white text-left">TokenID</h2>
                    <div className="relative inline-block w-full text-gray-700">
                        <select
                            onChange={(e) => {
                                setSelectedToken(e.target.value);
                            }}
                            className="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline"
                            placeholder="Regular input">
                            {gameMode.tokens.map(token => <option key={token.tokenId}>{token.tokenId}</option>)}
                        </select>
                    </div>
                    <ul style={{marginTop: 10}} className="cursor-pointer" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setCoopModalOpen(false);
                        startGame();
                    }} className="flex justify-end flex-wrap items-center">
                        <li style={{width: "100%"}}>
                            <p style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: 14,
                                border: gameMode.room && gameMode.room.length === 7 ? "1px solid #BDFF00" : "1px solid white",
                                color: gameMode.room && gameMode.room.length === 7 ? "#1D1D20" : "white",
                                background: gameMode.room && gameMode.room.length === 7 ? "#BDFF00" : "transparent"
                            }}
                               className="btn-sm">{coopHost ? "Start Room" : "Join Game"}</p>
                        </li>
                    </ul>
                </div>
            </Modal>

            <GameModal id="modal" ariaLabel="modal-headline" show={gameModalOpen}
                       handleClose={() => closeGame()}>
                <div style={{width: "100%", height: "100%"}} dangerouslySetInnerHTML={createIframe()}/>
            </GameModal>

        </div>
    );
}

export default Play;
